.parallax
{
    background-attachment: fixed;
    background-image: url('../HomePage/Assets/image2.jpg');
    background-position: bottom; 
    background-clip: content-box;
    background-size: cover;
    background-color: 'black';
    opacity: 1;
}
.slick-prev::before, .slick-next::before {
	color: black !important;
}
h2{
    font-size: 300%;

font-weight: 700;
}
.parallaxSecond
{
    background-attachment: fixed;
    background-image: url(../HomePage/Assets/image4.png);
}
.parallaxThird
{
    background-attachment: fixed;
    background-image: url(../HomePage/Assets/image4.jpg);
    background-size: cover;

}