.ant-card-body
{
    padding:'0px',
}
.ant-collapse-arrow
{
    margin-top: 1%;
}
.ant-collapse-header
{
    padding-bottom: 0px !important;
}