.about
{
    background-attachment: fixed;
    background-image: url(../About/Assets/image1.jpg);
    background-size: cover;
    background-position: center;
}
.ant-card-body
{
    padding:'0px',
}